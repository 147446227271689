import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      theme.palette.type === "dark" ? "#535353" : theme.palette.secondary.main,

    color: "#fff",
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: "1em",
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamilySecondary,
    textAlign: "center",
  },
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.root}>
        <Box>
          <Typography
            className={classes.header}
            variant="h5"
            component="h2"
            gutterBottom
          >
            Welcome to M.Saleh Inventory Dashboard
          </Typography>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default Welcome;
