export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request("https://msalehgroup.co.in/msapi/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        const newObj = {
          id: data.id,
          fullName: data.username,
        };
        console.log(newObj);
        localStorage.setItem("user", JSON.stringify(newObj));
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("user");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("user");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("user") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),

  getIdentity: () => {
    try {
      const { id, fullName } = JSON.parse(localStorage.getItem("user"));
      return Promise.resolve({ id, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
