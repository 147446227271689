import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  textHeading: {
    color: "#fff",
    fontWeight: "bold",
  },
  textTotal: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 40,
  },
  textColor: {
    color: "#fff",
    marginRight: 20,
  },
});

const BranchBox = ({ name, total, boxcolor1, boxcolor2 }) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginRight={isSmall ? "20px" : "0px"}
      marginLeft={isSmall ? "20px" : "0px"}
      sx={{
        backgroundColor: `${boxcolor1}`,
        height: 100,
        borderRadius: 20,
        padding: 15,
        "&:hover": {
          background: `linear-gradient(45deg, ${boxcolor1}, ${boxcolor2})`,
          cursor: "pointer",
        },
      }}
    >
      <Typography
        color="#fff"
        className={classes.textHeading}
        variant="subtitle1"
      >
        {name}
      </Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography color="#fff" variant="body1" className={classes.textColor}>
          Total Equipments:
        </Typography>
        <Typography color="#fff" variant="body1" className={classes.textTotal}>
          {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default BranchBox;
