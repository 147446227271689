import * as React from "react";
import { useState, useEffect } from "react";
import { useDataProvider, Error } from "react-admin";
import Welcome from "./Welcome";
import Branches from "./Branches";

export default () => {
  const dataProvider = useDataProvider();
  const [error, setError] = useState();
  const [products, setProducts] = useState([]);
  // const [phTotal, setPhTotal] = useState();

  useEffect(() => {
    dataProvider
      .getList("stock", {
        pagination: {},
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) return <Error />;
  if (products.length === 0) {
    return null;
  }
  const getTotalEquipment = (branchName) => {
    let filteredArray = [];

    if (branchName == "Port Harcourt") {
      filteredArray = products.filter((product) =>
        product.branch.startsWith("Port")
      );
    } else {
      filteredArray = products.filter(
        (product) => product.branch == branchName
      );
    }

    return filteredArray.length;
  };

  const phc = getTotalEquipment("Port Harcourt");
  const abuja = getTotalEquipment("Abuja");
  const kano = getTotalEquipment("Kano");
  const calabar = getTotalEquipment("Calabar");
  const lagos = getTotalEquipment("Lagos");

  return (
    <div>
      <Welcome />
      <Branches
        port={phc}
        abuja={abuja}
        kano={kano}
        calabar={calabar}
        lagos={lagos}
      />
    </div>
  );
};
