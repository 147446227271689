import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { EquipmentList, EditEquipment } from "./Equipment";
import { DeliveryList, EditDelivery } from "./Delivery";
import EquipmentIcon from "@material-ui/icons/Store";
import DeliveryIcon from "@material-ui/icons/DirectionsBus";
import Dashboard from "./Dashboard";
import { theme } from "./theme";
import authProvider from "./authProvider";

const App = () => (
  <Admin
    title="Example Admin"
    theme={theme}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={simpleRestProvider("https://msalehgroup.co.in/msapi")}
  >
    <Resource name="branches" />
    <Resource name="stocks" />
    <Resource
      name="equipments"
      list={EquipmentList}
      edit={EditEquipment}
      icon={EquipmentIcon}
    />
    <Resource
      name="deliveries"
      list={DeliveryList}
      edit={EditDelivery}
      icon={DeliveryIcon}
    />
  </Admin>
);

export default App;
