import * as React from "react";
import { useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles({
  text: {
    color: "green",
    fontWeight: "bold",
  },
  pending: {
    color: "orange",
    fontWeight: "bold",
  },
});

const MyStatusField = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return record[source] === "delivered" ? (
    <p className={classes.text}>Delivered</p>
  ) : (
    <p className={classes.pending}>Pending</p>
  );
};

export default MyStatusField;
