import React from "react";
import { Grid } from "@material-ui/core";
import BranchBox from "./BranchBox";

function Branches({ port, abuja, kano, calabar, lagos }) {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={4}>
        <BranchBox
          name="Port Harcourt"
          total={port}
          boxcolor1={"#f50057"}
          boxcolor2={"#00F59E"}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <BranchBox
          name="Abuja"
          total={abuja}
          boxcolor1={"#009688"}
          boxcolor2={"#96000E"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <BranchBox
          name="Kano"
          total={kano}
          boxcolor1={"#9c27b0"}
          boxcolor2={"#3BB027"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <BranchBox
          name="Calabar"
          total={calabar}
          boxcolor1={"#ff3d00"}
          boxcolor2={"#00C2FF"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <BranchBox
          name="Lagos"
          total={lagos}
          boxcolor1={"#673ab7"}
          boxcolor2={"#ab003c"}
        />
      </Grid>
    </Grid>
  );
}

export default Branches;
