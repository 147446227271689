import * as React from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  SimpleForm,
  Edit,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleList,
} from "react-admin";

import MyStockField from "./custom/MyStockField";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  rowEven: {
    backgroundColor: "Lavender",
  },
  headerCell: {
    backgroundColor: "MistyRose",
    fontWeight: "bold",
  },
  headerRow: {
    borderBottom: "2px solid grey",
  },
  textBold: {
    fontWeight: "bold",
  },
});

const equipmentFilters = [
  <TextInput source="q" label="Search by Equipment name" alwaysOn />,
  <TextInput label="Search by Barcode" source="barcode" />,
  <ReferenceInput
    source="branch_id"
    label="Branch"
    reference="branches"
    allowEmpty
  >
    <SelectInput optionText="branch_name" />
  </ReferenceInput>,
  <ReferenceInput source="stock_id" label="Stock" reference="stocks" allowEmpty>
    <SelectInput optionText="value" />
  </ReferenceInput>,
];

export const EquipmentList = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List filters={equipmentFilters} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name_}
          secondaryText={(record) =>
            record.stock == 0
              ? `${record.barcode} - in stock`
              : `${record.barcode} - out of stock`
          }
          tertiaryText={(record) => record.branch}
        />
      ) : (
        <Datagrid rowClick="edit" classes={classes} {...props}>
          <TextField source="id" />

          <TextField source="barcode" />

          <TextField
            className={classes.textBold}
            source="name_"
            label="Equipment Name"
          />
          <TextField source="branch" />
          <MyStockField source="stock" />
        </Datagrid>
      )}
    </List>
  );
};

const EquipmentTitle = ({ record }) => (
  <span>Equipment - {record ? `${record.barcode}` : ""}</span>
);

export const EditEquipment = (props) => (
  <Edit title={<EquipmentTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name_" />
      <TextInput source="barcode" />
      <TextInput source="branch" />
      <NumberInput source="stock" min={0} max={1} />
    </SimpleForm>
  </Edit>
);
