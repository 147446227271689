import * as React from "react";
import { useRecordContext } from "react-admin";
import ApprovedIcon from "@material-ui/icons/CheckCircle";
import NotApprovedIcon from "@material-ui/icons/Cancel";

const MyApprovedField = ({ source }) => {
  const record = useRecordContext();

  return record[source] === "1" ? (
    <ApprovedIcon color="primary" />
  ) : (
    <NotApprovedIcon color="error" />
  );
};

export default MyApprovedField;
