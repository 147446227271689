import * as React from "react";
import { useRecordContext } from "react-admin";
import { Chip } from "@material-ui/core";

const MyStockField = ({ source }) => {
  const record = useRecordContext();

  return record[source] === 1 ? (
    <Chip label="Out of Stock" color="primary" />
  ) : (
    <Chip label="In Stock" variant="outlined" />
  );
};

export default MyStockField;
