import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleList,
} from "react-admin";
import MyApprovedField from "./custom/MyAppprovedField";
import MyStatusField from "./custom/MyStatusField";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles({
  rowEven: {
    backgroundColor: "Lavender",
  },
  headerCell: {
    backgroundColor: "MistyRose",
    fontWeight: "bold",
  },
  headerRow: {
    borderBottom: "2px solid grey",
  },
  textBold: {
    fontWeight: "bold",
  },
});

const deliveryFilters = [
  <TextInput source="q" label="Search by Client name" alwaysOn />,
  <TextInput label="Search by Barcode" source="barcode" />,
  <ReferenceInput
    source="branch_id"
    label="Branch"
    reference="branches"
    allowEmpty
  >
    <SelectInput optionText="branch_name" />
  </ReferenceInput>,
];

export const DeliveryList = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List filters={deliveryFilters} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.clientname}
          secondaryText={(record) =>
            record.status == "delivered"
              ? `${record.equipmentbarcode} - Delivered`
              : `${record.equipmentbarcode} - Pending`
          }
          tertiaryText={(record) => record.pickupbranch}
        />
      ) : (
        <Datagrid rowClick="edit" classes={classes} {...props}>
          <TextField source="id" />
          <TextField source="equipmentbarcode" label="Barcode" />
          <TextField
            className={classes.textBold}
            source="equipmentName"
            label="Name of Equipment"
          />
          <TextField source="pickupbranch" label="Branch" />
          <DateField
            source="purchasedate"
            label="Date of Purchase"
            options={{
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }}
          />
          <TextField
            className={classes.textBold}
            source="clientname"
            label="Client"
          />
          <TextField source="clientphone" label="Client Phone" />
          {/* <TextField source="receivername" label="Receiver" /> */}
          <MyApprovedField
            source="approvedby"
            label="Approved"
            textAlign="center"
          />
          <MyStatusField source="status" />
        </Datagrid>
      )}
    </List>
  );
};

export const EditDelivery = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput disabled source="equipmentbarcode" />
        <TextInput source="equipmentName" />
        <TextInput source="clientname" />
        <TextInput source="clientaddress" />
        <DateInput source="purchasedate" />
        <TextInput source="clientphone" />
        <TextInput source="receivername" />
        <NumberInput source="approvedby" min={0} max={1} />
      </SimpleForm>
    </Edit>
  );
};
